<template>
    <div class="app-content-wrapper apps-wrapper --filemanager shadow-lg">
        <div :class="{open: isBlock}" class="app-overlay" @click="overlayApp" />
        <div :class="{open: isOpen}" class="app-sidebar">
            <vue-perfect-scrollbar
                :settings="{suppressScrollX: true, wheelPropagation: false}"
                class="h-100 rtl-ps-none ps scroll"
                style="height: 100%"
            >
                <div class="app-sidebar-body mt-1">
                    <v-list flat>
                        <v-subheader
                            class="text--disabled font-weight-medium text-uppercase"
                        >
                            Filter
                        </v-subheader>
                        <v-list-item-group>
                            <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                class="px-10"
                            >
                                <v-list-item-content>
                                    <v-list-item-title
                                        class="font-weight-medium"
                                        v-text="item.text"
                                    />
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </div>
            </vue-perfect-scrollbar>
        </div>
        <div class="app-content relative">
            <vue-perfect-scrollbar
                :settings="{wheelPropagation: false}"
                class="h-100 rtl-ps-none ps scroll"
                style="height: 100%"
            >
                <div class="app-body">
                    <v-row>
                        <v-col cols="12" class="ml-4">
                            <div class="card-title">Team Profiles</div>
                            <div
                                class="font-weight-medium ma-0 text--disabled"
                            >
                               <h6 class="mb-0">{{ teamList }}</h6>
                        </div>
                        </v-col>
                    </v-row>
                    <v-container class="grid filemanager-content pt-0">
                        <v-row>
                            <v-col v-for="(item, i) in getTeamsHurlingSort" :key="i" lg="4" class="">
                                <base-card>
                                    <div @click="goToProfile(item.id)" style="cursor: pointer;">
                                        <div class="pt-6" @click="goToProfile(item.id)">
                                            <v-img
                                                :src="`/assets/gaa/teams/icons/${item.code}.WEBP`"
                                                :alt="item.homeLogo"
                                                max-width="40"
                                                class="img_border h-auto ma-auto"
                                                ></v-img>
                                        </div>
                                        <v-card-text>
                                            <p
                                                class="card-text font-weight-bold ma-0 "
                                            >
                                                {{ item.name }}
                                            </p>
                                            <p
                                                class="text--disabled font-weight-medium ma-0 "
                                            >
                                                County Town
                                            </p>
                                            <p class="ma-0 body-2">
                                                Province - Competition
                                            </p>
                                        </v-card-text>
                                    </div>
                                </base-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </vue-perfect-scrollbar>
        </div>
    </div>
</template>
<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'ProfileList',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Profile'
    },
    components: {
        VuePerfectScrollbar
    },
    computed: {
        ...mapGetters(["getMunsterHurlingTeams","getTeamsHurlingSort"]),
        teamList(){
            return 'All';
        }
    },
    mounted(){
        this.fetchTeamsBySport("HURLING");
    },
    data: () => ({
        isOpen: false,
        isBlock: false,
        // item: 1,
        // itemsTwo: 0,
        items: [
            {text: 'All'},
            {text: 'Munster'},
            {text: 'Leinster'},
            {text: 'Connacht'},
            {text: 'Ulster'},
            {text: 'Other'}

        ]
    }),
    methods: {
        ...mapActions(["fetchTeamsBySport"]),
        goToProfile(id){
            this.$router.push(`/app/hurling/statistics/profile/${id}`)
        },
        appToggle: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        },
        overlayApp: function() {
            this.isOpen = !this.isOpen
            this.isBlock = !this.isBlock
        }
    }
}
</script>
<style lang="scss">

.img_border{
    border-style: solid;
    border-width: 2px;
}

.app-content-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    height: calc(100vh - 200px);

    .app-overlay {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
        display: none;
        z-index: 2;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            &.open {
                display: block;
                transition: all 0.3s ease-in;
            }
        }
    }
    .app-sidebar {
        width: 280px;
        position: relative;
        transition: all 0.3s ease-in;
        @media only screen and (max-width: 959px) {
            position: absolute;
            left: -340px;
            z-index: 5;
            height: calc(100vh - 120px);
            transition: all 0.3s ease-in;
            &.open {
                left: 0;
            }
        }
        .app-sidebar-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-sidebar-body {
            width: 280px;
        }
    }

    .app-content {
        width: calc(100% - 280px);
        @media only screen and (max-width: 959px) {
            width: 100%;
        }
        // margin-left: 30px;
        .app-header {
            padding: 0.75rem 1.25rem;
            margin-bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
        }
        .app-body {
            flex: 1 1 auto;
            height: calc(100% - 130px);
        }
        .app-footer {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 130px;
        }
    }
}
.app-icon {
    display: none;
    @media only screen and (max-width: 959px) {
        display: block;
    }
}
.eg-filemanager {
    background-color: #fff;
}
.filemanager-content {
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
}

.apps-wrapper {
    &.--filemanager {
        .nested-sidebar-toggle {
            @media (min-width: 959px) {
                display: none;
            }
        }
    }
}
</style>